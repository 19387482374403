<template>
    <div class="overKLEURENimg2">

            <div class="CLRS">
                <!-- <div class="CLRS-item" style="color: #262626">
                    <div class="CLRS-row1"><p class="p1">BLACK</p></div>	
                    <div class=CLRS-row2-col1><img src="images/rhizomeInPixelArt_BLCK.png"/></div>
                    <div class=CLRS-row2-col2>

                    <div class="CLRS-row3-col1"><p class="p1">#262626</p></div>
                </div> -->
                
                
                <div class="CLRS-item" style="color: #282522">
                    <div class="CLRname">BLACK<br><p></p></div>	
                    <div class=CLRhex>hex #282522 </div> 
                    <div class=CLRlab>lab (15,1,3)</div> 
                    <div class=CLRimg><img src="images/rhizomeInPixelArt_BLCK.png"/></div>
                    <div class="CLRgradient" style="background: linear-gradient(90deg, #282522 0%, #FFFFFF 100%);    "></div>                 
                </div>

                <div class="CLRS-item" style="color: #ffa196">
                    <div class="CLRname">FLUORESCENT ORANGE</div>	
                    <div class=CLRhex>hex #ffa196 </div> 
                    <div class=CLRlab>lab (85,60,35)</div> 
                    <div class=CLRimg><img src="images/rhizomeInPixelArt_FLRNG.png"/></div>
                    <div class="CLRgradient" style="background: linear-gradient(90deg, #ffa196 0%, #FFFFFF 100%);    "></div>              
                </div>

                <div class="CLRS-item" style="color: #ff8fdc">
                    <div class="CLRname">FLUORESCENT PINK</div>	
                    <div class=CLRhex>hex #ff8fdc </div> 
                    <div class=CLRlab>lab (80,65,-10)</div> 
                    <div class=CLRimg><img src="images/rhizomeInPixelArt_FLRPNK.png"/></div>
                    <div class="CLRgradient" style="background: linear-gradient(90deg, #ff8fdc 0%, #FFFFFF 100%);    "></div>              
                </div>

                <div class="CLRS-item" style="color: #f95369">
                    <div class="CLRname">BRIGHT RED <br> <p></p></div>	
                    <div class=CLRhex>hex #f95369 </div> 
                    <div class=CLRlab>lab (60,65,25)</div> 
                    <div class=CLRimg><img src="images/rhizomeInPixelArt_BRGHTRD.png"/></div>
                    <div class="CLRgradient" style="background: linear-gradient(90deg, #f95369 0%, #FFFFFF 100%);    "></div>              
                </div>  

                <div class="CLRS-item" style="color: #5e4883">
                    <div class="CLRname">PURPLE <br> <p></p></div>	
                    <div class=CLRhex>hex #5e4883 </div> 
                    <div class=CLRlab>lab (35,20,-30)</div> 
                    <div class=CLRimg><img src="images/rhizomeInPixelArt_PRPL.png"/></div>
                    <div class="CLRgradient" style="background: linear-gradient(90deg, #5e4883 0%, #FFFFFF 100%);    "></div>              
                </div>  

                <div class="CLRS-item" style="color: #007cc7">
                    <div class="CLRname">BLUE <br> <p></p></div>	
                    <div class=CLRhex>hex #007cc7 </div> 
                    <div class=CLRlab>lab (48,-15,-50)</div> 
                    <div class=CLRimg><img src="images/rhizomeInPixelArt_BL.png"/></div>
                    <div class="CLRgradient" style="background: linear-gradient(90deg, #007cc7 0%, #FFFFFF 100%);    "></div>              
                </div>  

                <div class="CLRS-item" style="color: #00858f">
                    <div class="CLRname">TEAL <br><p></p></div>	
                    <div class=CLRhex>hex #00858f </div> 
                    <div class=CLRlab>lab (50,-30,-15)</div> 
                    <div class=CLRimg><img src="images/rhizomeInPixelArt_TL.png"/></div>
                    <div class="CLRgradient" style="background: linear-gradient(90deg, #00858f 0%, #FFFFFF 100%);    "></div>              
                </div>                
                
                <div class="CLRS-item" style="color: #00b66e">
                    <div class="CLRname">GREEN <br><p></p></div>	
                    <div class=CLRhex>hex #00b66e </div> 
                    <div class=CLRlab>lab (65,-55,25)</div> 
                    <div class=CLRimg><img src="images/rhizomeInPixelArt_GRN.png"/></div>
                    <div class="CLRgradient" style="background: linear-gradient(90deg, #00b66e 0%, #FFFFFF 100%);    "></div>              
                </div>  
                
                <div class="CLRS-item" style="color: #fff144">
                    <div class="CLRname">YELLOW <br> <p></p></div>	
                    <div class=CLRhex>hex #fff144 </div> 
                    <div class=CLRlab>lab (95,-5,80)</div> 
                    <div class=CLRimg><img src="images/rhizomeInPixelArt_YLLW.png"/></div>
                    <div class="CLRgradient" style="background: linear-gradient(90deg, #fff144 0%, #FFFFFF 100%);    "></div>              
                </div>                    

                
                <!-- <div class="CLRS-item" style="color: #ff7477">						
                    <p class="p1">Fluo Orange</p>
					<img src="images/rhizomeInPixelArt_FLRNG.png"/>
                </div>
                <div class="CLRS-item" style="color: #ff48b0">						
                    <p class="p1">Fluo Pink</p>
					<img src="images/rhizomeInPixelArt_FLRPNK.png"/>
                </div>
                <div class="CLRS-item" style="color: ##ffe800">						
                    <p class="p1">Yellow</p>
					<img src="images/rhizomeInPixelArt_YLLW.png"/>
                </div>
                <div class="CLRS-item" style="color: #00a95c">						
                    <p class="p1">Green</p>
					<img src="images/rhizomeInPixelArt_GRN.png"/>
                </div>
                <div class="CLRS-item" style="color: #00838a">						
                    <p class="p1">Teal</p>
					<img src="images/rhizomeInPixelArt_TL.png"/>
                </div> -->
            </div>



    </div>    
</template>

<script>
    export default {
        name: 'overKLEURENimg2',
        methods: {
            lFilter: function(e) {
                this.$parent.mfilter(e);
                window.scrollTo(0,0); 
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


    .overKLEURENimg2 {


    }

    .overKLEURENimg2 .txt {

    }    
    
    .CLRS {
        padding: 25px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;   
   
    }

    .CLRS-item{
        border: solid 2px;
        width: 200px;
        margin: 10px;
        background-color: #ffffff;

    }

    .CLRname{
        font-size: 30px;
        border-bottom: 1px solid;
        padding: 5px;
    }

    .CLRimg img{
        max-width: 200px;
        padding: 10px;
    }   
    
    .CLRgradient{
        border-top: 1px solid;
        height: 50px;
   
    }  

    .CLRhex{
        border-bottom: 1px solid;
        padding: 5px;
    }  
    .CLRlab{
        border-bottom: 1px solid;
        padding: 5px;
    }  
   

    @media only screen and (min-width: 1280px) {
        .overKLEURENimg2 {
       

        }   
        

    } 
      

    @media only screen and (min-width: 1824px) {


    }  
</style>

